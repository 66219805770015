// CSS
import './src/scss/main.scss';

// JS
import 'jquery';
import lottie
  from 'lottie-web';
import 'popper.js';

import $
  from 'expose-loader?exposes[]=$&exposes[]=jQuery!jquery';

import 'bootstrap';

import arrowAnimation
  from './src/animations/arrow-down.json';

import headerArrowAnimation
  from './src/animations/arrow-down.json';

import arrowRightAnimation
  from './src/animations/arrows/black/right.json';

import arrowLeftAnimation
  from './src/animations/arrows/black/left.json';

import arrowGradientRightAnimation
  from './src/animations/arrows/gradient/right.json';

import arrowGradientLeftAnimation
  from './src/animations/arrows/gradient/left.json';

import mouse
  from './vendor/jquery.event.move.js';
import twentyTwenty
  from './vendor/jquery.twentytwenty.js';
import productIconAnimation
  from './src/animations/produkt.json';
import brandingIconAnimation
  from './src/animations/branding.json';
import designIconAnimation
  from './src/animations/ux-ui.json';
import techIconAnimation
  from './src/animations/tech.json';

const getJumNavigationOffsets = () => {
  return $('nav')?.outerHeight() || 0;
};

function Page() {
  var image = {
    width: 1920,
    height: 918
  };
  var target = {
    x: 1250,
    y: 460
  };

  var serviceIconAnimationDelay = 500; //In Milliseconds
  var claimAnimationDelay = 200; //In Milliseconds

  this.jobsSection = $('#jobs-teaser-section');
  this.casesSection = $('#case-preview');

  this.init = function() {
    var self = this;

    this.bindEvents();

    var url = new URL(window.location.href);

    const isMovetoJobs = url.searchParams.get('moveToJobs');
    const isMovetoCase = url.searchParams.get('moveToCases');
    if (isMovetoJobs || isMovetoCase) {
      setTimeout(()=>{
        $('html, body').animate({ scrollTop: self[isMovetoJobs ? 'jobsSection' : 'casesSection'].offset().top }, 'slow');
      }, 1200)

    }




    var downArrow = lottie.loadAnimation({
      container: document.getElementById('to-bottom'), // Required
      animationData: arrowAnimation,
      renderer: 'svg/canvas/html', // Required
      loop: true, // Optional
      autoplay: true, // Optional
      name: 'Down Arrow' // Name for future reference. Optional.
    });


    $('.arrow-element').each(function() {
      var arrowElement = $(this);


      if (arrowElement.hasClass('arrow-with-gradient')) {
        if (arrowElement.hasClass('arrow-right')) {
          self.bindArrowAnimation($(this), arrowGradientRightAnimation);
        } else if (arrowElement.hasClass('arrow-left')) {
          self.bindArrowAnimation($(this), arrowGradientLeftAnimation);
        }
      } else {
        if (arrowElement.hasClass('arrow-right')) {
          self.bindArrowAnimation($(this), arrowRightAnimation);
        } else if (arrowElement.hasClass('arrow-left')) {
          self.bindArrowAnimation($(this), arrowLeftAnimation);
        }
      }
    });


    switch ($('body').attr('id')) {
      case 'startpage':
        this.initStartPage();

        break;
      case 'agencypage':
        this.initAgencyPage();

        break;
      case 'casepage':
        this.initCasePage();

        break;

      default:
    }
  };


  this.bindEvents = function() {
    var self = this;

    $(document).on('input', 'textarea', function() {
      $(this).outerHeight(38).outerHeight(this.scrollHeight);
    });


    if ($(window).width() < 768) {
      image = {
        width: 681,
        height: 918
      };
      target = {
        x: 510,
        y: 460
      };
    }

    this.updateMarker();
    this.updateNavColor(0);

    $(window).on('resize', function() {
      self.updateMarker();
    });

    // Navigation color change on setion background
    $(document).on('scroll', function() {
      var scrollPosition = $(document).scrollTop();
      var body = $('body');

      if (body.attr('id') === 'startpage' || body.attr('id') === 'agencypage') {
        self.updateClaimVisibility(scrollPosition);
      }

      self.updateNavColor(scrollPosition);
    });


    $(document).on('click', '.navbar-toggler', function(e) {
      e.preventDefault();
      $(this).toggleClass('crossed');
      $('.navigation').toggleClass('show');
    });

    $(document).on('click', '.js-go-back', function(e) {
      e.preventDefault();
      history.back(1);
    });

    $(document).on('click', '.js-overscroll-header', function() {
      $('html, body').animate({ scrollTop: ($('#cover.header').height()) }, 'slow');
    });

    $(document).on('click', '.js-scroll-to-jobs', function(e) {
      e.preventDefault();
      e.stopPropagation();
      $('html, body').animate({ scrollTop: $('#jobs-teaser-section').offset().top - getJumNavigationOffsets() }, 'slow');
    });
  };

  this.bindNavigationStateHandler = function() {
    var self = this;



    $(window).scroll(function() {
      var jobsScrollTop = self.jobsSection.offset().top - getJumNavigationOffsets();
      var casesScrollTop = self.casesSection.offset().top - getJumNavigationOffsets();
      var scroll = $(window).scrollTop();

      if (scroll >= jobsScrollTop && scroll <= (jobsScrollTop + self.jobsSection.outerHeight())) {
        $('.nav-link').removeClass('active');
        $('.js-jobs-link').addClass('active');

      } else if (scroll >= casesScrollTop && scroll <= (casesScrollTop + self.casesSection.outerHeight())) {
        $('.nav-link').removeClass('active');
        $('.js-cases-link').addClass('active');
      } else {
        $('.nav-link').removeClass('active');
        $('.js-home-link').addClass('active');
      }
    });
  };

  this.bindArrowAnimation = function(arrowElement, animation) {

    let animationMenu = lottie.loadAnimation({
      container: arrowElement[0],
      loop: false,
      autoplay: false,
      animationData: animation,
      renderer: 'svg'
    });

    animationMenu.setSpeed(1.5);


    var direction = 1;
    arrowElement.parents('.js-animate-arrow-on-hover').on('mouseenter', function() {
      animationMenu.setDirection(direction);
      animationMenu.play();
    });

    arrowElement.parents('.js-animate-arrow-on-hover').on('mouseleave', function() {
      animationMenu.setDirection(-direction);
      animationMenu.play();
    });

  };

  this.updateClaimVisibility = function(scrollPosition) {
    var claim = $('.claim');
    if (scrollPosition >= ($('#cover').outerHeight() / 2) - 200) {
      claim.removeClass('show');
    } else {
      claim.addClass('show');
    }
  };

  this.updateNavColor = function(scrollPosition) {
    var body = $('body');

    $('.section').each(function() {

      var topPosition = $(this).offset().top;
      if (scrollPosition >= (topPosition - 80) && scrollPosition <= topPosition + $(this).outerHeight()) {

        if ($(this).attr('data-section-tone') === 'dark') {
          body.removeClass('light-content header-content');
          body.addClass('dark-content');
        } else if ($(this).attr('data-section-tone') === 'light') {
          body.addClass('light-content');
          body.removeClass('dark-content header-content');
        } else if ($(this).attr('data-section-tone') === 'header') {
          body.addClass('dark-content header-content');
          body.removeClass('light-content');
        } else if ($(this).attr('data-section-tone') === 'header-light') {
          body.addClass('light-content header-content');
          body.removeClass('dark-content');
        }

      }
    });
  };


  this.initCasePage = function() {
    var self = this;
    setTimeout(function() {
      self.updateClaimVisibility(0);
    }, claimAnimationDelay);

    $('.js-overview-image').on('load', function() {
      $('.js-overview-image-dummy').css('height', $(this).outerHeight());
    }).each(function() {
      if (this.complete) $(this).trigger('load');
    });

    $(window).on('resize', function() {
      $('.js-overview-image-dummy').css('height', $('.js-overview-image').outerHeight());
    });

  };


  this.addStartAnimations = function() {

    var params = {
      container: document.getElementById('product-icon'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: productIconAnimation
    };

    var anim = lottie.loadAnimation(params);

    var params2 = {
      container: document.getElementById('design-icon'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: designIconAnimation
    };

    var anim2 = lottie.loadAnimation(params2);

    var params3 = {
      container: document.getElementById('branding-icon'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: brandingIconAnimation
    };

    var anim3 = lottie.loadAnimation(params3);

    var params4 = {
      container: document.getElementById('tech-icon'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: techIconAnimation
    };

    var anim4 = lottie.loadAnimation(params4);


    var animationStarted = false;
    $(document).on('scroll', function() {
      var scrollPosition = $(document).scrollTop();

      if (!animationStarted && scrollPosition >= $('.agency-services').scrollTop() + 100) {
        setTimeout(function() {
          anim.play();
          anim2.play();
          anim3.play();
          anim4.play();
        }, serviceIconAnimationDelay);
      }
    });
  };


  this.initStartPage = function() {
    var self = this;

    this.addStartAnimations();
    this.bindNavigationStateHandler();


    // Make the underlining animation a little more delayed for each underline
    var delay = 0;
    $('.claim .gradient-underline').each(function() {
      $(this).css('animation-delay', delay + 's');
      delay += 0.3;
    });

    setTimeout(function() {
      self.updateClaimVisibility(0);
    }, claimAnimationDelay);
  };


  this.updateMarker = function() {

    var Marker = $('.marker');
    var footer = $('#footer');
    var windowWidth = footer.outerWidth();
    var windowHeight = footer.outerHeight();


    // Get largest dimension increase
    var xScale = windowWidth / image.width;
    var yScale = windowHeight / image.height;
    var scale;
    var yOffset = 0;
    var xOffset = 0;

    if (xScale > yScale) {
      // The image fits perfectly in x axis, stretched in y
      scale = xScale;
      yOffset = (windowHeight - (image.height * scale)) / 2;
    } else {
      // The image fits perfectly in y axis, stretched in x
      scale = yScale;
      xOffset = (windowWidth - (image.width * scale)) / 2;
    }

    Marker.css('top', (target.y) * scale + yOffset);
    Marker.css('left', (target.x) * scale + xOffset);
  };

}


$(document).ready(function() {

  $('.section').each(function() {
    $(this).attr('data-initial-top-pos', $(this).offset().top);
  });

  const page = new Page();
  page.init();

});
